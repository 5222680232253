import type { GetStaticPathsResult } from 'next';

import { NotImplementedError } from '@/utils/errors';

import type { ConfiguredLocaleString } from '@/services/isomorphic/I18NService';

/**
 * Given a list of static paths in {@link GetStaticPathsResult} form,
 * add the provided list locales to them as possible values for the `[lang]`
 * dynamic param.
 *
 * This will result in every path being statically pre-generated _at build time_
 * for every locale in the list provided.
 *
 * @param locales - The locales to add to the provided set of paths as possible
 * values for the `[lang]` dynamic param.
 *
 * @param staticPaths - The paths to statically pre-generate.
 * @returns The paths with the locales added.
 *
 * @throws A {@link NotImplementedError} if called with string paths.
 */
export const addLocaleStaticPaths = (
  locales: Array<ConfiguredLocaleString>,
  staticPaths: GetStaticPathsResult = {
    paths: [],
    fallback: 'blocking'
  }
): GetStaticPathsResult => {
  const { paths } = staticPaths;

  if (paths.length === 0) {
    return {
      ...staticPaths,
      paths: locales.map((locale) => ({
        params: {
          lang: locale
        }
      }))
    };
  }

  const newPaths = [];

  for (const path of paths) {
    if (typeof path === 'string') {
      throw new NotImplementedError(
        `"addLocaleStaticPaths" is not compatible with string paths.`
      );
    }

    for (const locale of locales) {
      newPaths.push({
        ...path,
        params: {
          ...path.params,
          lang: locale
        }
      });
    }
  }

  return { ...staticPaths, paths: newPaths };
};
