import type { GetStaticPathsResult } from 'next';
import type { ConfiguredLocaleString } from '@/services/isomorphic/I18NService';
import LocaleSelectionService from '@/services/isomorphic/LocaleSelectionService';
import { addLocaleStaticPaths } from './addLocaleStaticPaths';

/**
 * Generates a default set of
 * [static paths](https://nextjs.org/docs/pages/api-reference/functions/get-static-paths).
 *
 * No specific paths will be included, relying on
 * [`fallback: 'blocking'`](https://nextjs.org/docs/pages/api-reference/functions/get-static-paths#fallback-blocking)
 * instead so every path requested is generated server-side on demand and cached
 * for subsequent requests.
 *
 *
 * @param includeLocales - If the generated static paths should include values
 * for the `[lang]` dynamic parameter. This would result in the page being
 * statically pre-generated _at build time_ for the list of locales being
 * included.
 *
 * **Possible values:**
 * - `true`: _Default value._ A path will be generated for every
 * {@link LocaleSelectionService.listLocaleTags configured locale} in the current site as the `[lang]` param.
 * - `false`: No locales (i.e. no values for the `[lang] param`) will be
 * included in the static paths generated.
 * - An array: Will act as a custom list of locales to include as the possible
 * values of the `[lang]` param in the static paths generated.
 *
 * @returns Static paths for the page.
 */
export const getDefaultStaticPaths = (
  includeLocales: Array<ConfiguredLocaleString> | boolean = true
): GetStaticPathsResult => {
  let localeList: Array<ConfiguredLocaleString>;

  // Pattern-matching switch
  // Good resource on this: https://kyleshevlin.com/pattern-matching
  switch (true) {
    case !includeLocales: {
      localeList = [];
      break;
    }

    case Array.isArray(includeLocales): {
      localeList = includeLocales;
      break;
    }

    default: {
      localeList = LocaleSelectionService.listLocaleTags();
    }
  }

  // Add the paths for the [lang] param that wraps the entire page.
  return addLocaleStaticPaths(localeList, {
    paths: [],
    fallback: 'blocking'
  });
};
