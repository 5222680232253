import type { Constructor } from '@/type-utils';
import { mocked } from '@/configs';

import type { Nullable } from '@/type-utils';

import type {
  ILocalizedSite,
  LocalizedSiteTree
} from '@/constructs/LocaleSchema';
import { Country } from '@/constructs/Country';
import type { Language } from '@/constructs/Language';

import MockService, { MockState, ServiceMock } from '../MockService';
import type { LocaleSelectionService } from './LocaleSelectionService';

const initialState = {};

const MOCK_LOCALIZED_SITE_LIST = [
  {
    id: 'TESTBRAND_en-US',
    countryID: 'US',
    regionID: 'NA',
    locale: { language: 'en', country: 'US' },
    iconURL: new URL('http://localhost:3000/images/flags/4x3/us.svg'),
    aliases: {
      en: 'United States',
      de: 'Vereinigte Staaten',
      es: 'Estados Unidos'
    },
    url: new URL('https://decasite.com/')
  },
  {
    id: 'TESTBRAND_en-CA',
    countryID: 'CA',
    regionID: 'NA',
    locale: { language: 'en', country: 'CA' },
    iconURL: 'http://localhost:3000/images/flags/4x3/ca.svg',
    aliases: { en: 'Canada', de: 'Kanada', es: 'Canadá' },
    url: new URL('https://decasite.com/CA')
  },
  {
    id: 'TESTBRAND_fr-CA',
    countryID: 'CA',
    regionID: 'NA',
    locale: { language: 'fr', country: 'CA' },
    iconURL: 'http://localhost:3000/images/flags/4x3/ca.svg',
    aliases: { en: 'Canada', de: 'Kanada', es: 'Canadá' },
    url: new URL('https://decasite.com/fr-CA')
  }
] as const as ReadonlyArray<ILocalizedSite>;

const MOCK_LOCALIZED_SITE_TREE = {
  NA: {
    aliases: {
      en: 'North America',
      de: 'Nordamerika',
      es: 'América del Norte'
    },
    countries: {
      US: {
        regionID: 'NA',
        iconPath: 'images/flags/4x3/us.svg',
        aliases: {
          en: 'United States',
          de: 'Vereinigte Staaten',
          es: 'Estados Unidos'
        },
        languages: {
          en: {
            id: 'TESTBRAND_en-US',
            countryID: 'US',
            regionID: 'NA',
            locale: { language: 'en', country: 'US' },
            iconURL: new URL('http://localhost:3000/images/flags/4x3/us.svg'),
            aliases: {
              en: 'United States',
              de: 'Vereinigte Staaten',
              es: 'Estados Unidos'
            },
            url: new URL('https://decasite.com/')
          }
        }
      },
      CA: {
        regionID: 'NA',
        iconPath: 'images/flags/4x3/ca.svg',
        aliases: { en: 'Canada', de: 'Kanada', es: 'Canadá' },
        languages: {
          en: {
            id: 'TESTBRAND_en-CA',
            countryID: 'CA',
            regionID: 'NA',
            locale: { language: 'en', country: 'CA' },
            iconURL: new URL('http://localhost:3000/images/flags/4x3/ca.svg'),
            aliases: { en: 'Canada', de: 'Kanada', es: 'Canadá' },
            url: new URL('https://decasite.com/CA')
          },
          fr: {
            id: 'TESTBRAND_fr-CA',
            countryID: 'CA',
            regionID: 'NA',
            locale: { language: 'fr', country: 'CA' },
            iconURL: new URL('http://localhost:3000/images/flags/4x3/ca.svg'),
            aliases: { en: 'Canada', de: 'Kanada', es: 'Canadá' },
            url: new URL('https://decasite.com/fr-CA')
          }
        }
      }
    }
  }
} as const as LocalizedSiteTree;

/** Mock implementation for the {@link LocaleSelectionService}. */
export default class LocaleSelectionServiceMock extends ServiceMock<LocaleSelectionService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): LocaleSelectionService {
    return MockService.getMockOf(this.service) as LocaleSelectionService;
  }

  /**
   * The constructor to initialize the mocks.
   *
   * @param service - The service for this mock, used to remove a circular
   * dependency.
   */
  public constructor(private service: Constructor<LocaleSelectionService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(this.service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(
    service: Constructor<LocaleSelectionService>
  ): void {
    const mockEnabled: boolean = mocked.LocaleSelectionService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        list(): ReadonlyArray<ILocalizedSite> {
          return MOCK_LOCALIZED_SITE_LIST;
        },
        getAllLocalizedSites(): LocalizedSiteTree {
          return MOCK_LOCALIZED_SITE_TREE;
        },
        getLocalizedSiteByID(siteID: string): Nullable<ILocalizedSite> {
          return (
            MOCK_LOCALIZED_SITE_LIST.find(({ id }) => id === siteID) ?? null
          );
        },
        getLocalizedSiteForLocale(
          country: Country,
          language?: Language
        ): Nullable<ILocalizedSite> {
          return (
            MOCK_LOCALIZED_SITE_LIST.find(
              ({ locale: { language: siteLanguage, country: siteCountry } }) =>
                country === siteCountry &&
                (language === siteLanguage || !language)
            ) ?? null
          );
        }
      },
      { current: MOCK_LOCALIZED_SITE_LIST[0] },
      this.state
    );
  }
}
