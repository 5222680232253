import Service from '@/services/Service';

import siteCached from '@/services/utils/siteCached';
import type { Language } from '@/constructs/Language';
import { InvalidArgumentError, InvalidStateError } from '@/utils/errors';
import type {
  CountryMetadata,
  CountrySettingsForSite,
  ILocalizedSite,
  LocalizedSiteTree,
  RegionMetadata
} from '@/constructs/LocaleSchema';
import type { Nullable } from '@/type-utils';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import I18NService, {
  type ConfiguredLocaleString,
  type ILocale
} from '@/services/isomorphic/I18NService';
import { Country } from '@/constructs/Country';
import ConfigurationService, { type Config } from '../ConfigurationService';
import SiteSelectionServiceMock from './LocaleSelectionServiceMock';

/**
 * Service that handles operations related to locale selection.
 *
 * @see I18N Guide - `@/docs/guides/i18n`
 */
export class LocaleSelectionService extends Service {
  /** Site-cached getter for locale config. */
  @siteCached
  private get config(): Config<'locale'> {
    return ConfigurationService.getConfig('locale');
  }

  /**
   * Site-cached getter for country metadata from the locale config as a plain
   * JSON object.
   */
  @siteCached
  private get countryMetadata(): CountryMetadata {
    return this.config.getSetting('countries').toJSON() as CountryMetadata;
  }

  /**
   * Site-cached getter for region metadata from the locale config as a plain
   * JSON object.
   */
  @siteCached
  private get regionMetadata(): RegionMetadata {
    return this.config.getSetting('regions').toJSON() as RegionMetadata;
  }

  /**
   * Site-cached getter for country settings for the current brand, retrieved
   * from the locale config as a plain JSON object.
   */
  @siteCached
  private get currentCountrySettings(): CountrySettingsForSite {
    return this.config
      .getSetting('countrySettings')
      .toJSON() as CountrySettingsForSite;
  }

  /**
   * Assembles a {@link ILocalizedSite localized site} from the provided params.
   *
   * @param country - The country of the localized site to assemble.
   * @param language - The language of the localized site to assemble.
   * @param siteURL  - An optional URL for the localized. If unspecified, the
   * method will attempt to derive a URL for the site.
   *
   * @returns The localized site, in {@link ILocalizedSite} form.
   * @throws An {@link InvalidArgumentError} if called with an invalid country.
   */
  private assembleLocalizedSite(
    country: Country,
    language: Language,
    siteURL?: URL | string
  ): ILocalizedSite {
    const metadataForSpecifiedCountry = this.countryMetadata[country];

    if (!metadataForSpecifiedCountry) {
      throw new InvalidArgumentError(
        `No metadata was found for country "${metadataForSpecifiedCountry}".`
      );
    }

    const { aliases, iconPath } = metadataForSpecifiedCountry;

    // NOTE: Consider adding a LocaleModel
    const locale: ILocale = {
      language,
      country,
      toString: () => `${language}-${country}` as ConfiguredLocaleString
    };

    const url = new URL(siteURL ?? ''); // TODO: Add fallback URL

    // TODO: What should an ID be?
    const id = `${EnvironmentService.brand}_${locale.toString()}`;

    return {
      id,
      countryID: country,
      regionID: metadataForSpecifiedCountry.regionID,
      locale,
      iconURL: new URL(iconPath, EnvironmentService.baseURL),
      aliases,
      url
    };
  }

  /** Lists the available locales for this site. */
  @siteCached
  public list(): ReadonlyArray<ILocalizedSite> {
    const localizedSites: Array<ILocalizedSite> = [];

    for (const [country, countrySettings] of Object.entries(
      this.currentCountrySettings
    )) {
      const { enabled: countryEnabled, languages } = countrySettings;

      if (!countryEnabled) continue;

      for (const [language, languageSettings] of Object.entries(languages)) {
        if (!languageSettings) {
          throw new InvalidStateError(
            `Cannot list localized sites: There are no language settings for ` +
              `configured language "${language}".`
          );
        }

        const { enabled: languageEnabled, url } = languageSettings;

        if (!languageEnabled) continue;

        localizedSites.push(
          this.assembleLocalizedSite(
            country as Country,
            language as Language,
            url
          )
        );
      }
    }

    return localizedSites;
  }

  /**
   * Lists the available locales for this site, as
   * [IETF language tags](https://en.wikipedia.org/wiki/IETF_language_tag).
   *
   * @returns An array of all configure locales as IETF language tags.
   */
  @siteCached
  public listLocaleTags(): Array<ConfiguredLocaleString> {
    return this.list().map((localizedSite) => localizedSite.locale.toString());
  }

  /** Lists the available locales for this site. */
  @siteCached
  public getAllLocalizedSites(): LocalizedSiteTree {
    const tree: LocalizedSiteTree = {};

    for (const site of this.list()) {
      const {
        regionID,
        countryID,
        locale: { language }
      } = site;

      if (!tree[regionID]) {
        const metadataForRegion = this.regionMetadata[regionID];

        if (!metadataForRegion) {
          throw new InvalidStateError();
        }

        tree[regionID] = { ...metadataForRegion, countries: {} };
      }

      if (!tree[regionID].countries[countryID]) {
        const metadataForCountry = this.countryMetadata[countryID as Country];

        if (!metadataForCountry) {
          throw new InvalidStateError();
        }

        tree[regionID].countries[countryID] = {
          ...metadataForCountry,
          languages: {}
        };
      }

      tree[regionID].countries[countryID].languages[language] = site;
    }

    return tree;
  }

  /**
   * Given a {@link ILocalizedSite.id site ID}, get its corresponding
   * {@link ILocalizedSite localized site}.
   *
   * @param siteID  - The ID of the localized site.
   * @returns The localized site, as an {@link ILocalizedSite} object.
   */
  public getLocalizedSiteByID(siteID: string): Nullable<ILocalizedSite> {
    const allLocalizedSites = this.list();

    const matchingSite = allLocalizedSites.find(({ id }) => id === siteID);

    return matchingSite ?? null;
  }

  /**
   * Given a {@link Country country} (and optionally a
   * {@link Language language}), get the corresponding
   * {@link ILocalizedSite localized site}.
   *
   * @param country  - The country of the localized site.
   * @param language  - The language of the localized site.
   *
   * @returns The localized site, as an {@link ILocalizedSite} object.
   */
  public getLocalizedSiteForLocale(
    country: Country,
    language?: Language
  ): Nullable<ILocalizedSite> {
    const countrySettings = this.currentCountrySettings[country];

    if (!countrySettings) {
      throw new InvalidStateError(
        `No settings were found for country "${countrySettings}".`
      );
    }

    const {
      enabled: countryEnabled,
      languages,
      defaultLanguage
    } = countrySettings;

    if (!countryEnabled) return null;

    const actualLanguage = language ?? defaultLanguage;
    const languageSettings = languages[actualLanguage];

    if (!languageSettings) {
      throw new InvalidStateError(
        `No settings were found for language "${actualLanguage}".`
      );
    }

    const { enabled: languageEnabled, url } = languageSettings;

    if (!languageEnabled) return null;

    return this.assembleLocalizedSite(
      country as Country,
      actualLanguage as Language,
      url
    );
  }

  /**
   * Gets a {@link ILocalizedSite localized site object} for the current locale.
   *
   * @returns A {@link ILocalizedSite localized site object} for the current
   * locale.
   */
  @siteCached
  public get current(): ILocalizedSite {
    const { language, country } = I18NService.currentLocale;
    const currentSite = this.getLocalizedSiteForLocale(country, language);

    if (!currentSite) {
      // No localized site for current combination
      throw new InvalidStateError(
        `No settings were found for language "${language}"` +
          `${country ? ` and country "${country}"` : ''}.`
      );
    }

    return currentSite;
  }
}

export default LocaleSelectionService.withMock(
  new SiteSelectionServiceMock(LocaleSelectionService)
) as unknown as LocaleSelectionService;
